import React, {lazy, Suspense} from "react"
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

//import BlogPage, { loader as postsLoader } from './pages/Blog';
import HomePage from './pages/Home';
//import PostPage, { loader as postLoader } from './pages/Post';
import RootLayout from './pages/Root';

const BlogPage = lazy(() => { return import("./pages/Blog"); });

const PostPage = lazy(() => { return import("./pages/Post"); });

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: 'posts',
        children: [
          { index: true, element: <Suspense fallback={<p>Loading...</p>}><BlogPage /></Suspense>, loader: () => { return import("./pages/Blog").then((mod) => { return mod.loader(); }); }},
          { path: ':id', element: <Suspense fallback={<p>Loading...</p>}><PostPage /></Suspense>, loader: ({params}) => { return import("./pages/Post").then((mod) => { return mod.loader({params}); }); }},
        ],
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
